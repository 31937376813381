






































































































































































































































































@import "../css/variables.scss";

.subHeader {
  color: $grey-darken-1;
}

.title {
    background-color: $blue-title-2;
    padding: 16px !important;
    color: #FFFFFF;
  }

  .padNav {
    margin: 0 300px 0 0;
  }

