


































































































































































































































































































































































































@import "../css/variables";
@import "../css/components/_container.scss";

.title {
    background-color: $blue-title;
  }

