

































































































































































































































@import "../css/variables";
.login-form {
  margin-top: 200px;
}
.v-label {
  font-size: 14px;
}
.v-input {
  margin-bottom: 20px;
}

