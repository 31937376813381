





















































































































































@import "../css/variables";

.tab-btn {
  height: 25px;
  padding: 0;
  text-transform: capitalize;
}
.tab-btn {
  :global(.orange.darken-3) {
    opacity: 1 !important;
  }
}

.userAvatar {
  object-fit: contain;
  width: 150px;
  height: 150px;
}
