

















































































































































































































































.clickable {
  cursor: pointer;
}

.video-wrapper {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.controls-wrapper {
  height: 65px;
  position: relative;
  transition: top 0.2s ease-out;
  top: 10px;
}

.controls-wrapper.show {
  top: -65px;
}

/* PROGRESS BAR */

.progress-bar {
  z-index: 2;
  transition: top 0.2s ease-out;
}

.progress-bar-buffered,
.progress-bar-played,
.progress-bar-total {
  height: 3px;
  width: 0%;
  position: relative;
  transition: width 0.1s
}

.progress-bar-total {
  width: 100%;
  background-color: white;
  opacity: 0.75;
}

.progress-bar-buffered {
  background-color: grey;
  opacity: 0.75;
  top: -3px;
}

.progress-bar-played {
  min-width: 6px;
  background-color: var(--v-secondary-base);
  top: -6px;
}

.progress-bar-scrubber-new {
  height: 3px;
  width: 3px;
  position: absolute;
  right: 0px;
  background-color: var(--v-secondary-base);
  z-index: 2;
  box-sizing: content-box;
  background-clip: content-box;
  border-radius: 50%;
  transition: height 0.2s ease-out, width 0.2s ease-out, top 0.2s ease-out, right 0.2s ease-out;
  top: 0px; /* This appears to be required in FF but not Chrome */
}

.progress-bar:hover .progress-bar-scrubber-new{
  height: 12px;
  width: 12px;
  top: -5px; /* This appears to be required in FF but not Chrome */
  right: -6px;
}

/* END PROGRESS BAR */

/* PLAY/PAUSE BUTTON */

.play {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid white;
}

.pause::before, .pause::after {
  content: '';
  height: 20px;
  width: 5px;
  display: inline-block;
  background-color: white;
}

.pause::after {
  margin-left: 5px;
}

/* END PLAY/PAUSE BUTTON */

/* FULL SCREEN BUTTON */

.fullscreen-wrapper {
  right: 5;
}

.fullscreen {
  width: 20px;
  height: 20px;
  position: relative;
}

.corner {
  position: absolute;
  width: 8px;
  height: 8px;
}

.corner:nth-child(1) {
  left: 0;
  top: 0;
  border-top: 3px solid white;
  border-left: 3px solid white
}

.corner:nth-child(2) {
  right: 0;
  top: 0;
  border-top: 3px solid white;
  border-right: 3px solid white
}

.corner:nth-child(3) {
  bottom: 0;
  right: 0;
  border-bottom: 3px solid white;
  border-right: 3px solid white
}

.corner:nth-child(4) {
  left: 0;
  bottom: 0;
  border-bottom: 3px solid white;
  border-left: 3px solid white
}

/* END FULL SCREEN BUTTON */

/* TIMESTAMP */

.time {
  color: white;
  font-family: 'Source Sans Pro', sans-serif;
  text-align: center;
  margin: auto;
}

/* END TIMESTAMP*/
