













































































































@import '../../css/variables';

.title {
  background-color: $blue-title;
}
