










































































































































































































.roundedImg {
  border-radius: 4px 4px 0 0;
}
