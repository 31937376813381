






















































































@import '../../css/variables';

.root {
  background-color: $grey-1;
}

.lowerHeader {
  white-space:nowrap;
}

