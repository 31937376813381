





















































































































































































































































































































































































































































































































































































































































































































@import "../../css/variables";

#video-container {
  height: 70vh;
  background-color: $grey-darken-2;
  #player-controls-container {
    background-color: $grey-lighten-1;
  }
}

.row-height {
  height:100%;
}

.player {
  height: 100%;
  width: 100%;
  position: relative;
}

.hiddenPlayer {
  display: none;
}

