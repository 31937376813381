














































































































































































































































































































































@import "../../css/variables";

.selected {
  border: 4px solid $orange-text;
  outline: 4px solid $orange-text;
  outline-offset: -4px;
  max-width: 175px;
}

