@import '../variables';

.tabBackground {
  background-color: $grey-5!important;
  border-color: $grey-5!important;
}

.tabDialog {
  width: 400px;
}
