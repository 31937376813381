

























































































































@import "../css/variables";
.pwd-reset-form {
  margin-top: 200px;
}

.error-pwd-reset {
  position: absolute;
  top: 360px;
  display: flex;
  width: 90%;
}
.image-pwd-reset {
  width: 313px;
  margin: 20px 40px 0;
}
