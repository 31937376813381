// Colors
$orange-text: #E65100;
$gray-text: #757575;
$grey-lighten-3: #EEEEEE;
$grey-lighten-2: #E0E0E0;
$grey-lighten-1: #F5F5F5;
$grey-darken-1: #757575;
$grey-darken-2: #333333;
$grey-darken-3: #C4C4C4;
$grey-1: #E3E3E3;
$grey-2: #DCDCDC;
$grey-3: #F2F2F2;
$grey-4: #CCCCCC;
$grey-5: #E5E5E5;
$grey-6: #FAFAFA;
$black-text: #1B1B1B;
$blue-title: #17607D;
$blue-title-2: #1976D2;

//Width
$container-width: 1200px;
$container-padding: 10px 0;