














































































@import "../css/variables";
@import "../css/components/_container.scss";

.subHeader {
  color: $grey-darken-1;
}

.account-table {
  width: 100%;

  tr:nth-child(2n) td {
    background-color: var(--v-greyAlt1-base);
  }

  tr:hover td {
    background-color: var(--v-greyAlt2-base);
    cursor: pointer;
  }
}
