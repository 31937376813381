















































































































































































































































































@import '../../css/variables';
@import '../../css/components/_settingsTab';

.title {
  background-color: $blue-title;
}
