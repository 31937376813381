

































































































































































































































































































































































































































































































































































































































































@import '../../css/variables';

.title {
  background-color: $blue-title;
}

.btnGroup{
  margin: 4px;
  padding: 0 12px;
}

.divBackground {
  background-color: #ffffff;
}
