
















































































































































































































































































































































































@import "../../css/variables";

.tiles {
  max-height: 600px;
}

.item {
  cursor: pointer;
}

.selected {
  border: 1px solid $orange-text;
  outline: 5px solid $orange-text;
  outline-offset: 0px;
}

.navigation {
  min-width: 40px;
}

.fields {
  width: 100px;
  max-width: 100px!important;
}

.inputFrame input[type='number'] {
    -moz-appearance:textfield;
}
.inputFrame input::-webkit-outer-spin-button,
.inputFrame input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.centeredInput input {
  text-align: center;
}

