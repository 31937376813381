@import '../variables';

.settingsTable {
  max-height: 500px;
  overflow-y: auto;

  table {

    tr:nth-child(2n) td {
      background-color: $grey-lighten-1;
    }

    tr:hover td {
      background-color: $grey-5;
    }

    tr[active] td {
      background: $grey-4;
    }
  }
}